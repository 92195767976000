<template>
  <div class="mx-auto ModelSettings">
      <Loader text="Uploading Model" v-if="endpointCreationLoading" class="width:50px" />
      <div class="ModelSettings__settings-tab" v-else>
      <v-text-field
          outlined
          dense
          :value="model.name"
          @input="(val) => updateModel('name', val)"
          label="Model Name"
          class="mb-3"
      />

      <v-divider class="mx-n5 ModelSettings__divider" />

      <section>
          <v-autocomplete
              class="mb-3 mt-4"
              outlined
              dense
              :value="model.endpoint"
              @input="(val) => updateModel('endpoint', val)"
              item-text="name"
              item-value="name"
              :items="endpoints"
              label="Models"
              clearable
          >
          </v-autocomplete>
          <div v-if="!model.endpoint">
              <p class="text-h6 pt-4">Use own model</p>
              <v-file-input placeholder="Select your file" outlined color="primary" elevation="0" label="Upload model" v-model="file"></v-file-input>
              <v-btn @click.prevent="uploadOwnModel" outlined  v-if="!Array.isArray(file)" color="primary" elevation="0">Confirm</v-btn>
              <a v-bind:href="model.dataLocation" v-if="model.dataLocation">Model Data</a>
          </div>
      <p class="text-h6 pt-4">Model Input</p>
          <v-select
              class="mb-4"
              outlined
              dense
              :value="model.inputType"
              v-model="defaultInput"
              @input="(val) => updateInputType(val)"
              item-text="name"
              item-value="value"
              :items="inputTypes"
              label="Input Type"
              clearable
          ></v-select>
      <v-row v-for="(item, i) in fields" :key="i" class="mb-1" dense>
          <v-col>
              <v-text-field
                      outlined
                      dense
                      :value="item.name"
                      @input="(val) => updateModel(`input.fields[${i}].name`, val)"
                      label="Name"
              />
          </v-col>
          <v-col>
              <v-text-field
                      outlined
                      dense
                      label="Test Value"
                      :value="item.value"
                      @input="(val) => updateModel(`input.fields[${i}].value`, val)"
              />
          </v-col>
          <v-col cols="auto">
              <v-btn icon @click="removeInput(i)">
                  <v-icon color="error">mdi-delete</v-icon>
              </v-btn>
          </v-col>
      </v-row>

      <v-btn
              text
              :disabled="disableAddField"
              @click="addField"
              color="primary"
      >
          + Add Input
      </v-btn>
      </section>
      <OutputModel :model="output" />
      </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import OutputModel from '@/components/output-model/output-model.vue';
import {createNamespacedHelpers} from "vuex";
import lodashSet from "lodash/set";
import Loader from "@/components/loader/loader.vue";
const {
    mapGetters: modelsGetters,
    mapMutations: modelsMutations,
    mapActions: modelsActions,
} = createNamespacedHelpers('MLModels');
const {
    mapGetters: customerGetters,
} = createNamespacedHelpers('customer');

export default {
  name: 'ModelSettings',
  data() {
    return {
        defaultInput: {
            name: 'JSON Object',
            value: 'application/json'
        },
        inputTypes: [
            {
                name: 'JSON Object',
                value: 'application/json'
            },
            {
                name: 'File',
                value: 'application/octet-stream'
            }
        ],
        axios: null,
        file: []
    };
  },
  components: {
      Loader,
    OutputModel,
  },
  computed: {
      ...modelsGetters({
          model: 'MODEL',
          output: 'OUTPUT',
          fields: 'MODEL_INPUT_FIELDS',
          input: 'MODEL_INPUT',
          endpoints: 'ENDPOINTS',
          location: 'LOCATION_TO_UPLOAD',
          endpointCreationLoading: 'ENDPOINT_CREATION_LOADING',
      }),
      ...customerGetters({
          customer: 'CUSTOMER_USERS'
      }),
    disableAddField() {
      const fields = this.fields;
      if (!fields) return true;
      if (!fields[0]) return false;
      const lastField = fields[fields.length - 1];
      return !lastField?.name || !lastField?.value || this.model.inputType !== 'application/json';
    },
  },
  methods: {
      ...modelsMutations({
          setInput: 'SET_MODEL_INPUT',
          setFields: 'SET_MODEL_INPUT_FIELDS',
          setModel: 'SET_MODEL',
          setEndpoints: 'SET_ENDPOINTS',
      }),
      ...modelsActions(['uploadModelArtifacts', 'createEndpoint']),
      updateModel(prop, value) {
          const model = cloneDeep(this.model);
          lodashSet(model, prop, value);
          this.setModel(model);
      },
      updateInputType(value) {
          if(value !== 'application/json') {
            this.updateModel('input.fields', [{ name: 'base64File', value: '' }, { name: 'contentType', value: '' }])
          }
          this.updateModel('inputType',value)
      },
    addField() {
      const inputs = cloneDeep(this.fields);
      inputs.push({ name: '', value: '' });
      this.setFields(inputs);
    },
    removeInput(index) {
      this.fields.splice(index, 1);
    },
    uploadOwnModel() {
        this.uploadModelArtifacts({modelId: this.model.id, file: this.file });
        /*         const formData = new FormData();
        formData.append('file', e)
        console.log(this.location)
        this.axios.post(this.location, formData).then((response) => {
            console.log(this.location, response)
            this.updateModel('modelDataLocation', `${this.customer.customer_id}/${this.model.name}`)

        })*/
        const endpoints = cloneDeep(this.endpoints);
        endpoints.push({ name: this.model.endpoint })
        this.setEndpoints(endpoints)
        this.updateModel('endpoint', this.model.endpoint)
    }
  },
    mounted() {
        this.axios = window.axios.create({})
    }
};
</script>

<style lang="scss" scoped>
@import './model-settings';
</style>
