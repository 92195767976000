<template>
  <div class="MLModels">
    <Loader text="Loading Models" v-if="model.name === ''" />
    <div v-else>
      <header class="MLModels__header">
        <div
          class="MLModels__header-container d-flex justify-space-between align-center"
        >
          <div class="d-flex align-center">
            <p class="MLModels__header-title">
              <router-link
                to="/models"
                tag="span"
                class="MLModels__header-title-link"
              >
                Models
              </router-link>
              <v-icon small class="MLModels__header-title-icon">
                mdi-chevron-right
              </v-icon>
              <span class="MLModels__header-title-name" v-if="model.name">
                {{ model.name }}
              </span>
              <span class="MLModels__header-title-no-name" v-else>
                Model name here
              </span>
            </p>
          </div>
        </div>
      </header>
      <div class="MLModels__main-content">
        <v-row>
          <v-col cols="12" lg="8" v-if="!model.endpoint">
            <CodeEditor
                :value="model.dockerfileString"
                @input="(val) => updateModelField('dockerfileString', val)"
                :language="lang"
                class="mb-3"
                :placeholder="''"
                :height="'700px'"
            />
          </v-col>
          <v-col cols="12" lg="4">
            <v-card :width="600" class="SideSettings" :elevation="1">
              <ModelSettings />
                <v-row class="ml-0">
                    <v-btn
                            color="success"
                            :disabled="runningModel|| editModelLoading"
                            @click="testRun"
                            :loading="runningModel"
                            elevation="0"
                            outlined
                            class="mr-5 mb-5 ml-5"
                    >
                        <v-icon class="mr-3" right>mdi-bug-check</v-icon> Run Model
                    </v-btn>
                    <v-btn
                            color="primary"
                            elevation="0"
                            :disabled="editModelLoading"
                            @click="save"
                            :loading="editModelLoading"
                    >
                        Save Model
                    </v-btn>
                  <v-btn
                      color="primary"
                      elevation="0"
                      v-if="!model.endpoint"
                      :disabled="editModelLoading"
                      @click="saveAndBuild"
                      :loading="editModelLoading"
                      class="mr-5 mb-5 ml-5"
                  >
                    Build Docker Image
                  </v-btn>
                </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/loader/loader.vue';
import ModelSettings from "./ml-model-settings/model-settings.vue";
import {createNamespacedHelpers} from "vuex";
import cloneDeep from "lodash/cloneDeep";
import CodeEditor from "@/components/code-editor/code-editor.vue";
import lodashSet from "lodash/set";
import {getModel, getModelType} from "@/util/actionsModels";
const {
    mapGetters: modelsGetters,
    mapActions: modelsActions,
    mapMutations: modelsMutations,
} = createNamespacedHelpers('MLModels');
const { mapActions: workflowDetailsActions } =
    createNamespacedHelpers('workflows/details');

export default {
  name: 'Models',
  components: {
    CodeEditor,
    ModelSettings,
    Loader,
  },
  data() {
    return {
      lang: 'dockerfile',
      crumbs: [
        {
          text: 'Models',
          disabled: false,
          exact: true,
          to: { name: 'Models' },
        },
        {
          text: 'Model',
          disabled: true,
        },
      ],
    };
  },
  computed: {
      ...modelsGetters({
          model: 'MODEL',
          editModelLoading: 'EDIT_MODEL_LOADING',
          runningModel: 'RUNNING_MODEL',
          fields: 'MODEL_INPUT_FIELDS'
      }),
  },
  mounted() {
      this.setFields([]);
      const { modelId } = this.$route.params;
      if (modelId !== 'new') {
          this.fetchModel(modelId);
      }
      this.fetchEndpoints();
  },
  methods: {
      ...modelsMutations({
          setFields: 'SET_MODEL_INPUT_FIELDS',
          setRunningModel: 'SET_RUNNING_MODEL',
          setOutput: 'SET_OUTPUT',
          setModel: 'SET_MODEL',
      }),
      ...modelsActions(['fetchModel', 'fetchEndpoints', 'updateModel', 'clearModel', 'buildDockerImage']),
      ...workflowDetailsActions(['executeSingleAction']),
      save() {
          const id = this.model.id
          this.updateModel({ model: {...cloneDeep(this.model), id: undefined }, id });
      },
    async saveAndBuild() {
      const id = this.model.id
      await this.updateModel({ model: {...cloneDeep(this.model), id: undefined }, id });
      await this.buildDockerImage({ id });
    },
    updateModelField(prop, value) {
      const model = cloneDeep(this.model);
      lodashSet(model, prop, value);
      this.setModel(model);
    },
    async testRun() {
          this.setRunningModel(true);
          this.setOutput({ model: {}, action: {} });
          const inputs = {};
          this.fields.forEach((input) => {
              inputs[input.name] = input.value;
          });
          const options = {
              data: { fields: this.fields, endpoint: this.model.endpoint, inputType: this.model.inputType },
              action: 'aws_execute_ml_model',
              input: inputs,
              isServerless: true,
          };
          const action = { input: inputs };
          try {
              const data = await this.executeSingleAction(options);
              const model = getModel({ result: data.result });
              action.output = data.data;
              const modelEntity = cloneDeep(this.model);
              this.setModel({...modelEntity, output: getModelType({ result: data.result })});
              this.setOutput({ output: model, action, taskId: data.taskId });
              this.save()
          } catch (error) {
              this.setOutput({ action });
          } finally {
              this.setRunningModel(false);
          }
      },
  },
    beforeDestroy() {
        this.clearModel();
    },
};
</script>

<style lang="scss">
@import './ml-model';
</style>
